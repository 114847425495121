import React from 'react'
import Layout from "../../../components/layout"
import Link from "gatsby-link";
import BodyClassName from 'react-body-classname';
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import "animate.css/animate.css";
import ScrollAnimation from 'react-animate-on-scroll';
import acomLogo from './ancestry-logo.svg'
import hintLeaf from './hint-leaf.svg'
import iconBeauty from './icon_beauty.svg'
import iconDelight from './icon_delight.svg'
import iconUsability from './icon_usability.svg'
import bonsaiColors from './bonsai-colors.png'
import bonsaiAvatars from './avatars.png'
import bonsaiButtons from './buttons.png'
import bonsaiTimelines from './timelines.png'
import bonsaiTree from './bonsai-tree.png'
import styled from '@emotion/styled'

const RED = styled.span`
  color: #c00;
`
const H4 = styled.h4`
 text-transform: uppercase;
 letter-spacing: .05em;
 color: #c00;
`
const Stack = styled.div`
  margin-top:40px;
  background-color: #efefef;
`

const CatTitle = styled.p`
font-size: .75em;
text-transform: uppercase;
color: #000;
opacity: .5;
letter-spacing: .1  em;
`

const H1 = styled.h1`
margin-top: 0;
`


export default () => (
  <Layout>
  <PageTransition>
  <BodyClassName className="">
  <div className="page">

  <article className="">

  <section className="contentSection">
    <div className="row">
      <div className="col-xs-9">
      <header>
        <Link to="./casestudies"><CatTitle>Case Study</CatTitle></Link>
        <H1>Bonsai: The Ancestry Interface</H1>
      </header>
      </div>
    </div>

    <div className="row">
      <div className="col-xs-12 col-sm-7 col-md-8">
        <H4>The Problem</H4>
      <p className="introText">
 A growing design team in Utah and California needed more comprehensive guidelines for new products, and the existing library did not meet the needs of design concepts.</p>
 <p className="introText">There was also a feeling that Ancestry's existing interface was not meeting the expectations of a new wave of tech-savvy customers purchasing DNA products. </p>


<H4>Solution</H4>
        <p className="introText">
          As part of a more significant effort to unify visual design and to streamline front-end development, a comprehensive library of design components was created and implemented across all of Ancestry’s web applications.
        </p>
<p className="introText">
 This effort aimed to improve the usability of Ancestry’s products on mobile devices, begin a transition to a more energetic and playful brand, and to speed up product development.
        </p>
          <p><small><em>I led this initial effort from 2014-2017, after which I created a standards committee to oversee the work. Several designers were involved in the overall design concept and rollout.</em></small></p>
      </div>

      <div className="col-xs-12 col-sm-5 col-md-4">
        <ScrollAnimation animateIn="fadeIn" duration={.5}>
        <p><img alt="" className="full" src={acomLogo} style={{"maxWidth":"300px"}}/></p>          
          <ul>
            <li>Conducted a complete visual audit of Ancestry’s web and mobile properties.</li>
            <li>Created overall design concept and principles.</li>
            <li>Produced a living style guide in conjunction with Ancestry‘s Front-End development team.</li>
            <li>Collaborated with marketing and branding departments to coordinate the release of new styles.</li>
            <li>Made ongoing improvements for performance, visual design, & WCAG2.0 accessibility compliance.</li>
          </ul>
        </ScrollAnimation>
      </div>

    </div>

    </section>


    <div className="mt-40 bonsaiPebbleGradient" style={{"color":"#fff"}}>
      <section className="contentSection">
      <h2 className="metalab-heading">Say Hi to Bonsai.</h2>
      <div className="row">
      <div className="col-xs-12 col-sm-7">
      <p>In tradition with naming product features at Ancestry after trees, Bonsai became the name we would use to refer to our visual design style.</p>
      <p>Bonsai aimed to develop a core style that would provide the necessary utility, but also enhance the enjoyable, heartfelt, and rewarding work that our customers put into their family trees.</p>


      </div>
      <div className="col-xs-12 col-sm-5 col-md-4">
        <ScrollAnimation animateIn="tada" duration={1}>
          <img alt="" className="full" src={hintLeaf} />
          </ScrollAnimation>
        </div>
      </div>
      </section>
    </div>


    <div className="" >
      <section className="contentSection">
      <h2 className="metalab-heading"><span className="numbers">1</span>Defining Bonsai Principles</h2>
      <p>Three principles would act as the primary guidelines for how we approached the design project. Ancestry needed a system that would improve the overall usability—especially for mobile apps—but we also wanted to ensure that it would bring character and fun to the product.</p>
      <div className="row">
      <div className="col-xs-12 col-sm-4">
      <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
      <img alt="" className="full" src={iconBeauty}/>
      </ScrollAnimation>
      <h2>Beauty</h2>
      <blockquote><em>“It must have philosophy, strong artistry, human quality behind it to be a bonsai”</em><br/>
— John Yoshio Naka, Bonsai Master</blockquote>
    </div>

      <div className="col-xs-12 col-sm-4">
      <ScrollAnimation animateIn="fadeInUp2" duration={.5} delay={100}>
      <img alt="" className="full" src={iconUsability}/>
      <h2>Usability</h2>
      <blockquote><em>“The most common user action on a website is to flee.”</em><br/>—Edward Tufte</blockquote>
      </ScrollAnimation>
      </div>

      <div className="col-xs-12 col-sm-4">
      <ScrollAnimation animateIn="fadeInUp2" duration={.5} delay={200}>
      <img alt=""  className="full" src={iconDelight}/>
      <h2>Delight</h2>
      <blockquote><em>”We also need to build products that bring joy and excitement, pleasure and fun, and, yes, beauty to people’s lives.”</em><br/>
–Donald Norman</blockquote>
      </ScrollAnimation>
      </div>
      </div>
    </section>
    </div>

<div style={{"background":"#efefef"}}>
    <section className="contentSection" >

      <h2 className="metalab-heading"><span className="numbers">2</span>Introducing Color & Contrast</h2>
      <div className="row">
      <div className="col-xs-12">


      <p>Bonsai introduced contrast as an element in the interface, allowing users to view their tree with a dark background, or customize their tree with the color of their choosing.</p>


      <ScrollAnimation animateIn="fadeInUp2" duration={1}>
      <img alt="" className="full" src={bonsaiColors}/>
      </ScrollAnimation>

      <p>Previously, Ancestry's interface primarily used a muted color palette that reflected the colors in old photos. Bonsai introduced a vibrant color palette and encouraged using it in new ways. The colors were carefully selected to go well together and to support contrast ratios needed for WCAG Level 2 accessibility compliance.</p>

      </div>
      </div>

    </section>
    </div>


    <section className="contentSection">
      <h2 className="metalab-heading"><span className="numbers">3</span> Interface Elements</h2>
      <div className="row">
      <div className="col-xs-12 col-md-8">
      <ScrollAnimation animateIn="fadeIn" duration={.5}>
      <div className="p-20">
        <img alt="" className="full" src={bonsaiButtons}/>
        <img alt="" className="full" src={bonsaiAvatars}/>
        <img alt="" className="full" rc={bonsaiTimelines}/>
      </div>
        </ScrollAnimation>
      </div>
      <div className="col-xs-12 col-md-4">
      <p>
        We designed and created interface components for all standard web elements, as well as for items unique to Ancestry (Ancestor profiles, timelines, etc.).
      </p>
      <p>
       Every component was tested to ensure backward compatibility with existing functions, and also for accessibility compliance and performance.
      </p>
      <p>A comprehensive library was created in Sketch to give designers easier access to the latest versions and to help speed up design work.</p>


      </div>
      </div>
    </section>


    <div className="bonsaiPebbleGradient" style={{"color":"#fff"}}>
    <section className="contentSection">
      <div className="row">
      <div className="col-xs-8">
      <p>The family tree was set on a dark background to give better clarity to the individual components, and to reduce screen glare for customers who use the site for long hours each day.</p>
      </div>
      </div>
      <div className="row">
      <div className="col-xs-12">
      <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
      <div className="">
        <img  alt="" className="full" src={bonsaiTree}/>
      </div>
        </ScrollAnimation>
      </div>

      </div>
    </section>
    </div>

    <section className="contentSection">
      <div className="row">
      <div className="col-xs-12">
      <h2 className="metalab-heading">Summary</h2>
      <p>Bonsai was released to customers in 2015. The initial release was constrained to a significant update of Ancestry’s mobile app and was considered very successful.</p>

      <p>Bonsai has held up well, and the basic framework created in 2014 has enabled significant product launches, including AncestryDNA, which has now reached over 10 million customers.</p>

      <p>The Ancestry mobile app, designed using the Bonsai framework and was promoted by Apple and won design awards, and has maintained a customer rating of 4+ stars since it’s launch.  </p>

      <p>The design standards for the web are a continuing work in progress, and the team continues to use the processes and frameworks developed to improve and evolve. In July of 2018, we released the latest version, which included updates to meet web accessibility guidelines.  </p>

</div>


<div className="col-xs-12">
<h3>Additional Thoughts & Considerations</h3>

<p><small>Ancestry’s dedicated and vocal customer base introduced complexity. While the update was successful for the mobile app, the implementation of web properties received mixed reviews from customers.  Data indicated that most customers preferred the new design and felt it looked professional and clean; however, several long-term customers experienced difficulty with new styles.</small></p>

<p><small>The rollout on the web proved to be difficult, and two years later, development teams are still struggling to update pages to include standard components which render the new designs.</small></p>
      </div>
      </div>
      <div className="row">
      <div className="col-xs-12">
      <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
      <div className="">

      </div>
        </ScrollAnimation>
      </div>

      </div>
    </section>

    </article>

  </div>

  </BodyClassName>
  </PageTransition>
  </Layout>

)
